import { useEffect } from 'react';

const SiftScript = ({ userID }) => {
    useEffect(() => {
        if (!userID) return;

        const generateSessionID = () => {
            const sessionID = Math.random().toString(36).substring(2);
            sessionStorage.setItem('user_session_id', sessionID);
            return sessionID;
        };

        const script = document.createElement('script');
        const head = document.querySelector('head');
        const _sift = window._sift = window._sift || [];
        const _session_id = generateSessionID();

        script.async = true;
        script.type = "text/javascript";
        script.src = "https://cdn.sift.com/s.js";
        script.append(`
            _sift.push(['_setAccount', '${process.env.NEXT_PUBLIC_SIFT_BEACON_KEY}']);
            _sift.push(['_setUserId', '${userID}']);
            _sift.push(['_setSessionId', '${_session_id}']);
            _sift.push(['_trackPageview']);
        `);
        head.appendChild(script);

        return () => {
            head.removeChild(script);
        };
    }, [userID]);

    return null;
};

export default SiftScript;
