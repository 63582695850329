"use client";
import "../styles/globals.css";
import "@/assets/Fonts/stylesheet.css"
import { Provider } from "react-redux";
import { Suspense, useEffect, useState } from "react";
import store from "@/services/Redux/store";
import { storeAssigned, _logoutFunction, } from "@/services/Methods/normalMethods";
import { _storeCustomerTypes } from "@/services/Methods/commonPayload";
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import Script from "next/script";
import Loader from "@/comman/Loader";
import { usePathname, useSearchParams } from "next/navigation";
import SiftScript from "@/services/Methods/SiftScript.hook";
import localStorageCall from "@/services/Methods/localstorage.hook";

function RootLayout({ children }) {
  const [loading, setLoading] = useState(true);

  const pathname = usePathname();

  useEffect(() => {
    setTimeout(() => setLoading(false), 2000);
  }, [pathname]);

  const isUserLogin = localStorageCall().getItem('Token');

  return (
    <html lang="en" className="scroll-smooth">
      <head>
        <title>Viago</title>
        <link rel="icon" href="/public/favicon.ico" />
        <link rel="icon" href="/favicon.ico" sizes="any" />
      </head>
      <body className={(pathname === "/" || pathname === `/${storeAssigned()}`) ? "home-banner-section" : ""} suppressHydrationWarning={true}>
        <Script
          src={`https://maps.googleapis.com/maps/api/js?key=${process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY}&libraries=places`}
          strategy="beforeInteractive"
        />
        <Provider store={store}>
          {isUserLogin && process.env.NEXT_PUBLIC_SIFT_BEACON_KEY && (
            <SiftScript userID={isUserLogin} />
          )}
          {loading ? (
            <Loader />
          ) : (
            <Suspense fallback={"loading ................"}>
              {children}
            </Suspense>
          )}
        </Provider>
        {/* <GlobalStyle /> */}
      </body>
    </html>
  );
}

export default RootLayout;